<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <activity
        :activityMaxShow="meta && meta.perPage ? meta.perPage : 10"
        :issueIdModal="data.id"
        :type="meta && meta.type ? meta.type : 'issue'"
        class="lineHeight--initial"
        ref="activity"
    />
  </base-form-item>
</template>

<script>
import Activity from '../../../../activity/Activity.vue';
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';
import EventBus from '../../../../../eventBus';

export default {
  mixins: [abstractForm],
  name: 'index',
  components: {Activity, BaseFormItem},
  mounted() {
    EventBus.$on('FETCH_ACTIVITIES', () => {
      this.$refs.activity.getActivity(true);
    });
  },
  beforeDestroy() {
    EventBus.$off('FETCH_ACTIVITIES');
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.activities {
  line-height: initial;
}
</style>
